<template>
    <v-container class="siteheader">
        <v-row justify="center" class="pt-3">
            <v-col cols="4" align-self="center">
                <v-row justify="start" style="display:flex; gap:5px;">
                    <a :href="language == 'es' ? `https://pancanal.com/tarifas-maritimas` : `https://pancanal.com/en/maritime-tariffs/`"
                        rel="home">
                        <v-img src="../assets/images/house.svg" contain width="50px" class="header-icon"></v-img>
                    </a>
                    <!-- videos -->
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-img v-bind="attrs" v-on="on" src="../assets/images/help.svg" contain width="50px"
                                    class="header-icon"></v-img>
                            </template>
                            <v-card>
                                <v-card-title class="text-h5 grey lighten-2">
                                    {{ language == 'es' ? "Instrucciones de uso" : "Usage guide" }}
                                </v-card-title>
                                <v-card-text>
                                    <video v-if="dialog" width="100%" height="auto" controls>
                                        <source :src="`/videos/${language}-calculadora.mp4`" type="video/mp4">
                                    </video>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-row>
            </v-col>
            <v-col cols="4" align-self="center">
                <v-row justify="center" class="nav_logo">
                    <a href="https://pancanal.com/tarifas-maritimas" rel="home">
                        <img alt="Autoridad del Canal de Panamá" class="img-fluid" src="../assets/images/p-logo.png">
                    </a>
                </v-row>
            </v-col>
            <v-col cols="4" align-self="center">
                <v-row justify="end" margin no-gutters>
                    <div class="lan_switch">
                        <section id="icl_lang_sel_widget-3" class="widget widget_icl_lang_sel_widget">
                            <div class="lang_sel_list_horizontal wpml-ls-sidebars-language-widget wpml-ls wpml-ls-legacy-list-horizontal"
                                id="lang_sel_list">
                                <ul>
                                    <li
                                        class="icl-en wpml-ls-slot-language-widget wpml-ls-item wpml-ls-item-en wpml-ls-first-item wpml-ls-item-legacy-list-horizontal">
                                        <a href="/en" class="wpml-ls-link" style="margin-right:3px">
                                            <span class="wpml-ls-native icl_lang_sel_native" lang="en">EN</span></a>
                                    </li>
                                    <li
                                        class="icl-es wpml-ls-slot-language-widget wpml-ls-item wpml-ls-item-es wpml-ls-current-language wpml-ls-last-item wpml-ls-item-legacy-list-horizontal">
                                        <a href="/es" class="wpml-ls-link">
                                            <span class="wpml-ls-native icl_lang_sel_native">ES</span></a>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </div>
                    <!-- <div id="res_menu_b" onclick="mOpen()"><span></span></div> -->
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import i18n from '../i18n';

export default {
    name: 'AcpHeader',
    components: {
    },
    data: () => ({
        language: i18n.locale,
        dialog: false,
    }),
};
</script>